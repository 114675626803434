<template>
  <vx-card>
    <form>
      <div class="mb-4 px-4">
        <fieldset class="vx-row rounded-lg pb-3 px-1 pt-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('details') }}</strong></legend>
          <div class="vx-col my-2 md:w-2/3 w-full">
            <vs-input v-validate="'required'" class="w-full" name="promocode" :placeholder="$t('promoCode')" v-model="promo.code" />
            <span class="text-danger text-sm" v-show="errors.has('promocode')">{{ errors.first('promocode') }}</span>
          </div>
          <div class="vx-col my-2 md:w-1/3 w-full">
            <vs-input type="number" v-validate="'required'" class="w-full" name="discount" :placeholder="$t('fields.discount_amount')" v-model="promo.percent" />
            <span class="text-danger text-sm" v-show="errors.has('discount')">{{ errors.first('discount') }}</span>
          </div>
        </fieldset>
      </div>
      <div class="vx-row mb-4">
        <div class="vx-col w-full">
          <fieldset class="rounded-lg px-1" style="border-color: rgba(255,255,255,0.6)">
            <legend class="mx-3 px-2"><strong>{{`${$t('select')} ${$t('type')}`}}</strong></legend>
            <!-- <span><strong>Select market type</strong></span> -->
            <div class="mx-4 mt-3 mb-5">
              <v-select v-validate="'required'" :options="types" name="type" :placeholder="$t('selectOne')" v-model="promo.dep_type" :label="`name_${$i18n.locale}`" :reduce="opt => opt.value" />
              <span class="text-danger text-sm" v-show="errors.has('type')">{{ errors.first('type') }}</span>
            </div>
          </fieldset>
        </div>
      </div>
      <div class="mb-4">
        <fieldset class="rounded-lg px-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('attachTo') }}</strong></legend>
          <ul class="vx-row centerx px-4">
            <li class="mx-4 mt-4 mb-3 pr-2">
              <vs-radio v-validate="'required'" name="type" v-model="child_type" vs-value="all">{{ $t('all') }}</vs-radio>
            </li>
            <li class="mx-4 mt-4 mb-3 pr-2">
              <vs-radio v-validate="'required'" name="type" v-model="child_type" vs-value="brand">{{ $t('brand') }}</vs-radio>
            </li>
            <li class="mx-4 mt-4 mb-3 pr-2">
              <vs-radio v-validate="'required'" name="type" v-model="child_type" vs-value="category">{{ $t('category') }}</vs-radio>
            </li>
            <li class="mx-4 mt-4 mb-3 pr-2">
              <vs-radio v-validate="'required'" name="type" v-model="child_type" vs-value="products">{{ $t('product') }}</vs-radio>
            </li>
            <li class="mx-4 mt-4 mb-3 pr-2">
              <vs-radio v-validate="'required'" name="type" v-model="child_type" vs-value="collections">{{ $t('comboProduct') }}</vs-radio>
            </li>
          </ul>
          <span class="text-danger text-sm pl-4" v-show="errors.has('type')">{{ errors.first('type') }}</span>
          <div v-if="child_type && child_type !== 'all'" class="vx-row" :key="child_type">
            <div class="vx-col w-full mb-4 mx-2">
              <template v-if="child_type !== 'campaign'">
                <v-select multiple v-validate="'required'" :options="linkOptions" name="childId" :placeholder="$t('selectOne')" v-model="promo.child_ids" :label="`name_${$i18n.locale}`" :reduce="opt => opt.id" />
              </template>
              <template v-else>
                <v-select multiple v-validate="'required'" :options="linkOptions" name="childId" :placeholder="$t('selectOne')" v-model="promo.child_ids" :label="`title_${$i18n.locale}`" :reduce="opt => opt.id" />
              </template>
              <span class="text-danger text-sm" v-show="errors.has('childId')">{{ errors.first('childId') }}</span>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="mb-4">
        <fieldset class="rounded-lg px-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('fields.promoRule') }}</strong></legend>
          <ul class="vx-row centerx px-4">
            <li class="mx-4 mt-4 mb-3 pr-2">
              <vs-radio v-validate="'required'" name="type" v-model="rule" vs-value="deadline">{{ $t('fields.timeRestricted') }}</vs-radio>
            </li>
            <li class="mx-4 mt-4 mb-3 pr-2">
              <vs-radio v-validate="'required'" name="type" v-model="rule" vs-value="usecounts">{{ $t('fields.maxUseRestricted') }}</vs-radio>
            </li>
          </ul>
          <span class="text-danger text-sm pl-4" v-show="errors.has('type')">{{ errors.first('type') }}</span>
          <div v-if="rule === 'deadline'" class="vx-row px-4 mb-6 mt-1">
            <div class="vx-col w-1/2">
              <flat-pickr v-validate="'required'" v-model="promo.from" :config="configdateTimePicker" class="w-full inputx" name="from" placeholder="Bashlangyc sene saylan" />
              <span class="text-danger text-sm" v-show="errors.has('stockMinQt')">{{ errors.first('stockMinQt') }}</span>
            </div>
            <div class="vx-col w-1/2">
              <flat-pickr v-validate="'required'" v-model="promo.to" :config="configdateTimePicker" class="w-full inputx" name="to" placeholder="Gutarysh sena saylan" />
              <span class="text-danger text-sm" v-show="errors.has('stockMinQt')">{{ errors.first('stockMinQt') }}</span>
            </div>
          </div>
          <div v-if="rule === 'usecounts'" class="vx-row px-4 mb-4">
            <div class="vx-col my-2 w-full">
              <vs-input v-validate="'required'" class="w-full" name="use_max" placeholder="Maximum usage" v-model="promo.use_max" />
              <span class="text-danger text-sm" v-show="errors.has('use_max')">{{ errors.first('use_max') }}</span>
            </div>
          </div>

        </fieldset>
      </div>
      <div class="mb-4">
        <fieldset class="rounded-lg px-1" style="border-color: rgba(255,255,255,0.6)">
          <legend class="mx-3 px-2"><strong>{{ $t('fields.properties') }}</strong></legend>
          <ul class="vx-row centerx px-2">
            <li class="vx-col my-4 ml-2 mr-4 flex">
              <vs-switch name="isActive" ref="isActive" v-model="promo.isActive" />
              <label class="ml-3">{{ $t('isActive') }}?</label>
            </li>
          </ul>
        </fieldset>
      </div>
      <vs-button type="filled" @click.prevent="submitForm" class="mt-5 block">{{$t('submit')}}</vs-button>
    </form>
  </vx-card>
</template>

<script>
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
import { Russian } from 'flatpickr/dist/l10n/ru.js'
export default {
  components: {
    vSelect,
    flatPickr: () => import('vue-flatpickr-component')
  },
  data () {
    return {
      promo: {
        code: '',
        percent: null,
        dep_type: '',
        child_ids: [],
        from: null,
        to: null,
        use_max: null,
        isActive: false
      },
      child_type: '',
      linkOptions: [],
      rule: '',
      types: [
        {value: 'both', name_en: 'Both', name_tm: 'Ikisi hem', name_ru: 'Оба'},
        {value: 'market', name_en: 'Market', name_tm: 'Market', name_ru: 'Маркет'},
        {value: 'express', name_en: 'Express', name_tm: 'Ekspress', name_ru: 'Экспресс'}
      ],
      type: null,
      configdateTimePicker: {
        enableTime: true,
        time_24hr: true,
        locale: Russian
      }
    }
  },
  created () {
  },
  watch: {
    async child_type (newVal, oldVal) {
      this.$validator.pause()
      this.$nextTick(() => {
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field))
        this.$validator.resume()
      })
      if (newVal !== oldVal) {
        switch (newVal) {
        case 'products':
          this.promo.child_ids = []
          await this.$http.get('/products', { params: { isActive: true } }).then(response => {
            this.linkOptions = response.data.data
          })
          break
        case 'brand':
          this.promo.child_ids = []
          await this.$http.get('/brands', { params: { isActive: true } }).then(response => {
            this.linkOptions = response.data.data
          })
          break
        case 'category':
          this.promo.child_ids = []
          await this.$http.get('/categories', { params: { isActive: true } }).then(response => {
            this.linkOptions = response.data.data
          })
          break
        case 'campaign':
          this.promo.child_ids = []
          await this.$http.get('/campaigns', { params: { isActive: true } }).then(response => {
            this.linkOptions = response.data.data
          })
          break
        case 'collections':
          this.promo.child_ids = []
          await this.$http.get('/collections', { params: { isActive: true } }).then(response => {
            this.linkOptions = response.data.data
          })
          break
        }
      }
    }
  },
  methods: {
    submitForm () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.$http.post('/promos/add', {
            ...this.promo,
            child_type: this.child_type,
            percent: Number(this.promo.percent)
          }).then(response => {
            if (response.status === 200) {
              this.$vs.notify({
                title: 'Success',
                text: 'Promo successfully created!',
                iconPack: 'feather',
                icon: 'icon-check-circle',
                color: 'primary'
              })
              this.$router.push('/promos')
            }
          }).catch(err => {
            this.$vs.notify({
              title: 'Error',
              text: err.message,
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'danger'
            })
          })
        } else {
          // form have errors
        }
      })
    }
  }
}
</script>
